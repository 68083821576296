import { ImageProps } from '@code/ui';

type Key = 'page-6-cta-m';

export const imgMap: Record<Key, Pick<ImageProps, 'blurDataURL' | 'src'>> = {
  'page-6-cta-m': {
    src: 'assets/page-6-cta-m.png',
    blurDataURL: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB0AAAAgCAIAAABhFeQrAAAACXBIWXMAABYlAAAWJQFJUiTwAAAEKUlEQVR4nLWRy08bVxSH549I82gjI2NTKiIXTIwADwQNmsF2/KgtYR6xh4wFGzM2ZJDlSEXmNSDwIkQTqEjVZlFZrrvAC1v2kg1iYY0sI1nKJlmwgL+giwoJFpX5kathklKoVOnT6Nx7z/numXOpiefC/wF1/XFUFKOiOPtCAv/d+yzMPwvzxEiYeC7czjseCsE1HgqNh0La7mZfSEQKL3JuQsM7Hgr5fvBHRXF5RX79Zmt5RV5YWiHq6Xh8Oh5HcGvv9k87mVzu9ZutTUXZVJSFpZWFpZX5VApMx+PzqVRUFJ96vDf1utzuqCge1uubipLJZuElLC0vE3tUFF1ud3B09CY0vPOp1GG9nslmCZuKEhVF3R3joZDL7b4h1JDTuakoaq2WLxYrVTVfLM69fIm+fn73DmA+N5c2vJzDkclmT8/PKlV1LZ0Ojo4OOZ1DTqcwNVkolzLZLNRr6fTtvD00XSiX8sXi8EiQczg4h2PI6eQcDimZqFTVQrkE5I113Hc9pJwiEXcRAIZjF1dltVbbO9jPF4sYDsOxJFNHD02jfHgkCBpehmO1SfCupdNqrYa5q7WalEygWAvDsT00PTwSXEun88VioVxaS6eFqUk+IlAMx+q8KNj59ZfDer1SVYEwNdlD00gGuEbeWK9U1dPzs9Pzs0w2KyUTaP/Sy3yyk6BQLh2dHBPv8Eiw295LMrvtvd6AX95Yx/SPTo6PTo4L5RK83fZeimEHG2js8O4d7L//+KFSVTFlZCMTF8TnpMVVeevtTi6/+/7jhz9P/9o72F9clb0BP8OxlLXLZu2yXVGzg96Af+9gH1Sqai6/y3AsMgEfEfiIEJ+TiBdSPiJ4A35vwH/Vy1624w34c/ldUCiXlJ1thh0kUpfPg+KYNCtvrOfyuwvyMj3Q32F73GF7jJxLrxXqC6xdtrAwsfV2B/z2x+/xOUnbrMvnoQf6x/hwdCb2aluJzsQsnVZtQsOrvcTaZaMH+vGbr7aVxVUZE+QjAk4tnVZ6oB+4fJ7oTOzHpQVrlw0GqABl6bR+fhsfERZXZSmZkJIJPAXK4CVdY5ow6Lj0Wq6qx/hwTJoVpiaFqcmYNMtwLKTwImA4dowPk+V13o5PM/EG/GFhgvDFYozii9KGt63d0tZusXRam1vMwNz67ZNBhuDyeVq+a9XWIB9elH8O1dxiJl5S6fJ5tBAdQAleD61o9xE0vM0Xa62XPDr31IliXEzKmlvM3X327j47+UsdVJPZ1GQ2NbeY7339AFvEa3/Sh8mSI4B8/AFibJK44TWYjAaTUedFLwiIt8lsQjJA79odLZdeg8n41YN7uLOt3fKo43vtI+BIV9nWbmm1PPp37537d7WNEP6p8nqoh8YmcOf+3WswmIwPjU34AtSTpQ7qG6NBhy5De2QwGbWxdqnjb/1lZeBVR2MlAAAAAElFTkSuQmCC'
  },
};
